import React, { useState } from 'react';

function HiddenDropdown() {
    const [isDropdownVisible, setDropdownVisible] = useState(false);
  
    const handleMouseOver = () => {
      setDropdownVisible(true);
    };
  
    const handleMouseOut = () => {
      setDropdownVisible(false);
    };
  
    const handleDropdownMouseOver = () => {
      setDropdownVisible(true);
    };
  
    const handleDropdownMouseOut = () => {
      setDropdownVisible(false);
    };

  return (
    <div style={{ display: 'inline-block', position: 'relative' }}>
      <span onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} style={{ cursor: 'pointer' }}>
        <b>stuff</b>
      </span>
      {isDropdownVisible && (
        <div className='dropdown' onMouseOver={handleDropdownMouseOver} onMouseOut={handleDropdownMouseOut} style={{ position: 'absolute', top: '100%', left: 0, zIndex: 1, background: '#4c4747', border: '0px', padding: '10px' }}>
          <ul style={{ listStyleType: 'none', margin: 0, padding: 0, textAlign: 'center' }}>
            <li style={{ margin: 0, padding: 0 }}><a href='https://www.nexusmods.com/users/9187920?tab=user+files'>Palworld Mods</a></li>
            <li style={{ margin: 0, padding: 0 }}><a href='https://pwmodding.wiki/docs/category/lua-modding'>Palworld Modding Docs</a></li>
            <li style={{ margin: 0, padding: 0 }}><a href='https://github.com/xrandox/TehsTrails'>GW2 Marker Packs</a></li>
            <li style={{ margin: 0, padding: 0 }}><a href='https://blishhud.com/modules/?module=Teh.BHUD.Blacklist_Buddy_Module'>GW2 Blacklist Buddy</a></li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default HiddenDropdown;
