import logo from './Images/superthumb.png';
import './App.css';
import HiddenDropdown from './Components/HiddenDropdown';

function App() {
  return (

    <div className="container">
      <head>
        <link href="https://fonts.googleapis.com/css2?family=Hack&display=swap" rel="stylesheet"/>
      </head>
      <body className='container'>
        <header className="content">
          <img src={logo} className="" alt="" />
          <p>
            Hi. I'm xTeh. I make useful <HiddenDropdown/> sometimes.
          </p>

          <a href='https://ko-fi.com/S6S0HWFO7' target='_blank' rel="noreferrer"><img height='36' style={{border:"0px",height:"36px"}} src='https://storage.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
        </header>
      </body>
    </div>
  );
}

export default App;
